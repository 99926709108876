body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale
}

.App {
  overflow: hidden;
}

header{
  padding: 4.5vh 11.1vw 0 11.1vw;
  justify-content: space-between;
  align-items: center;
  width: 77.8vw
}

header, form, label, #Icons {
  display: flex
}  

#Logo {
  width: 221.75px;
  height: 41px;
  cursor: pointer;
}

button {
  width: 189px;
  background: #031E29;
  color: white;
  font-family: inherit;
  font-weight: 500;
  cursor: pointer
}

button:hover {
  background: #6572B5
}

button, input {
  height: 51px;
  border-radius: 8px;
  font-size: 16px;
  border: none;
  outline: none
}

h1 {
  font-size: 40px;
  font-weight: normal;
  line-height: 54.86px;
  height: 179px;
  width: 660px;
  margin-top: 16.35vh;
  margin-bottom: 2.9vh
}

h1, input, #Send, footer {
  margin-left: 11.1vw
}

form {
  flex-direction: column
}

form div {
  display: flex;
  flex-direction: column
}

span {
  font-weight: bold;
  color: #031E29
}

label {
  font-weight: 600;
  font-size: 15px;
  line-height: 18.29px;
  height: 34px;
  margin-left: 11.8vw;
  align-items: center
}

input {
  width: 427px;
  background: #F7F7F7;
  font-family: inherit;
  padding-left: 16px
}

input, footer {
  color: #7D7D7D;
  font-weight: 400
}

#Send {
  margin-top: 3.15vh
}

#Response {
  position: relative;
  margin: 2vh 0 0 11.1vw
}

#Illustration {
  right: 6.5vw;
  top: 29.5vh;
  width: 356.21px;
  height: 295.08px
}
#Illustration2 {
  display: none
}

#Illustration, #Icons, footer {
  position: absolute
}

#Icons {
  justify-content: flex-end;
  right: 11.1vw;
  bottom: 12vh
}

#Icons a {
  margin-left: 5.55vw
}

#Icons a img {
  height: 44px;
  width: 44px
}

footer {
  font-size: 18px;
  width: 230px;
  left: 11.1vw;
  bottom: 38px
}

@media only screen and (max-width: 1440px) {
  h1{
    font-size: 32px;
    width: 605px
  }
  #Icons a img {
    height: 36px;
    width: 36px
  }
  footer{
    font-size: 16px
  }
}

@media only screen and (min-height: 850px) and (max-width: 1300px) {
  .App {
    background-image: none !important
  }
}

@media only screen and (min-height: 1360px) and (max-width: 1925px) {
  .App {
    background-image: none !important
  }
}

@media only screen and (max-width: 1150px) {
  header{
    padding: 4vh 0 0 5vw
  }
  h1 {
    height: 215px;
    width: 513px;
    margin: 6vh 0 25.5vh 5vw
  }
  #Beta {
    position: absolute;
    left: 5vw;
    top: 36vh
  }
  form div {
    flex-direction: row
  }
  label, input {
    margin-left: 4.9vw;
    padding-right: 0
  }
  #Send {
    margin: 0 0 0 27px
  }
  button:hover {
    background: #031E29
  }
  #Response {
    margin: 2vh 4.9vw
  }
  #Icons {
    width: 100%;
    justify-content: center;
    right: unset;
    margin: 50px auto;
    position: static
  }
  #Icons a {
    margin: 0 50.5px
  }
  footer {
    left: 0;
    margin-left: 0;
    width: 100%;
    text-align: center
  }
}

@media only screen and (max-height: 1150px) {
  #Beta {
    top: 44vh
  }
}

@media only screen and (max-width: 415px) {
  header {
    padding: 52px 0 0 18px
  }
  #Logo {
    height: 26px;
    width: 140px
  }
  h1{
    width: 351px;
    height: 81px;
    font-size: 22px;
    line-height: 27px
  }
  #Beta {
    top: 35vh;
    width: 160px;
    height: 43px;
    font-size: 16px
  }
  #Illustration {
    display: none
  }
  #Illustration2 {
    position: absolute;
    display: block;
    top: 28vh;
    right: 0;
    width: 151px;
    height: 191px
  }
  label {
    margin: 4vh 0 23px 18px
  }
  form div {
    flex-direction: column;
  }
  input {
    width: 322px;
    margin-bottom: 44px
  }
  #Send {
    margin: 0 auto
  }
  #Response {
    margin: 30px auto
  }
  #Icons {
    margin: 0 auto
  }
  #Icons a {
    margin: 25px 28px 0 28px
  }
  #Icons a img {
    height: 32px;
    width: 32px
  }
  footer {
    bottom: 13px;
    font-size: 10px;
  }
}

@media only screen and (max-width: 429px) and (max-height: 927px) {
  .App {
    height: unset !important
  }
  footer {
    position: static;
    margin: 40px 0 13px 0
  }
}

@media only screen and (min-width: 360px) and (max-width: 380px) and (min-height: 450px) and (max-height: 667px) {
  #Beta {
    margin-top: 40px
  }
  #Illustration2 {
    margin-top: 25px
  }
}
